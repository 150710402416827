
import {
  _360ProvidersLinks,
  _360ProvidersParams,
  _360ProvidersSuffixes,
  _360Providers,
} from "@evercam/shared/constants/360Providers"
import SnapshotEditor from "@/components/SnapshotEditor"
import { MESSAGES_TYPES } from "@evercam/360/components/constants"
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"
import RecordingsDialog from "@/components/3dAppsDialogs/RecordingsDialog"
import FrameViewer from "@/components/3dAppsDialogs/FrameViewer"
import CreateUploadDialog from "@/components/ingest/uploadDialogs/CreateUploadDialog"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import { useLayoutStore } from "@/stores/layout"
import { useConnectorStore } from "@/stores/connector"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { useCommentStore } from "@/stores/comment"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  InfoPage,
  WatermarkedImageDownloadOrigin,
} from "@evercam/shared/types"

export default {
  name: "Viewer360",
  meta: {
    pageId: AnalyticsEventPageId.ThreeSixty,
  },
  components: {
    RecordingsDialog,
    FrameViewer,
    SnapshotEditor,
    CreateUploadDialog,
  },
  middleware({ redirect, $permissions }) {
    const projectStore = useProjectStore()

    if (
      !$permissions.project.has._360View() ||
      !$permissions.user.can.access._360() ||
      $permissions.project.has.go()
    ) {
      redirect(`${projectStore.projectRoute}/info/${InfoPage.ThreeSixtyView}`)
    }
  },
  async asyncData({ route }) {
    const projectId = route.params.project_exid
    await useCommentStore().init(projectId, "360")
  },
  data: () => ({
    recordingsCameraExid: undefined,
    project360: null,
    providerLinks: _360ProvidersLinks,
    providerParams: _360ProvidersParams,
    providersSuffixes: _360ProvidersSuffixes,
    isEditingImage: false,
    currentSnapshot: null,
    initialQueryParams: {},
    currentQueryParams: {},
    data360: {},
    WatermarkedImageDownloadOrigin,
    upload360Dialog: false,
    upload360Object: undefined,
    ingestUploadTypes: INGEST_UPLOADS_TYPES,
    origin: "",
    formatedComments: [],
  }),
  head() {
    return {
      title: `${
        this.projectStore.selectedProjectName || "Evercam"
      } | 360° View`,
      meta: [
        { charset: "utf-8" },
        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(
      useProjectStore,
      useAccountStore,
      useLayoutStore,
      useConnectorStore,
      useIngestFileUploaderStore,
      useCommentStore
    ),
    firstCamera() {
      return this.projectStore.selectedProjectCameras[0] || {}
    },
    params() {
      var extraParams =
        this.project360.integrationType === _360Providers.Evercam
          ? `&unit=${this.accountStore.unitSystem}&` +
            new URLSearchParams(this.initialQueryParams)
          : ""

      return `${this.providerParams[this.project360.integrationType]}${
        this.project360.providerID
      }${extraParams}`
    },
  },
  watch: {
    "$route.query.viewer"(_, old) {
      if (!old) {
        return
      }
      this.isEditingImage = false
    },
    "commentStore.comments": {
      handler(newValue, oldValue) {
        this.formatedComments = newValue.map((comment) => {
          return this.formatComment(comment)
        })

        if (!this.isViewerReady) {
          return
        }

        if (!oldValue.length) {
          this.sendMessage({
            type: MESSAGES_TYPES.commentsFetched,
            comments: this.formatedComments,
          })

          return
        }

        const newComment = newValue.filter((newComment) =>
          oldValue.every((oldComment) => oldComment.id !== newComment.id)
        )
        if (newComment.length) {
          this.sendMessage({
            type: MESSAGES_TYPES.commentCreated,
            comment: newComment,
          })

          return
        }

        const deletedComment = oldValue.filter((oldComment) =>
          newValue.every((newComment) => newComment.id !== oldComment.id)
        )

        if (deletedComment.length) {
          this.sendMessage({
            type: MESSAGES_TYPES.commentDeleted,
            comment: deletedComment[0],
          })

          return
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    this.initialQueryParams = { ...this.$route.query, user: "user" }
    await this.get360Data()
    this.$analytics.saveEvent(AnalyticsEvent.ThreeSixtyOpen)
  },
  methods: {
    async get360Data() {
      let response = await fetch(
        this.$config.public.firebaseDbLink +
          `data/projects/360/${this.projectStore.selectedProjectExid}.json`
      )
      this.project360 = await response.json()
      if (!this.project360.providerPrefix) this.project360.providerPrefix = ""

      if (!this.project360.integrationType) {
        this.project360.integrationType = "Evercam"
      }

      if (!this.project360.providerSuffix) {
        this.project360.providerSuffix =
          this.providersSuffixes[this.project360.integrationType]
      }
    },
    async onMessageReceived(data) {
      switch (data?.type) {
        case MESSAGES_TYPES.liveView:
          this.openRecordings(data.cam)
          break
        case MESSAGES_TYPES.editSnapshot:
          if (!this.isEditingImage) {
            this.openImageEditor(data?.url, data?.data360)
          }
          break
        case MESSAGES_TYPES.analyticsEvent:
          this.saveAnalyticsEvent(data?.action)
          break
        case MESSAGES_TYPES.history:
          this.pushQueryParams(data?.history)
          break
        case MESSAGES_TYPES.shareLink:
          this.shareLink()
          break
        case MESSAGES_TYPES.upload360:
          this.on360UplaodDialogOpen()
          break
        case MESSAGES_TYPES.ready:
          this.origin = data?.origin
          this.isViewerReady = true
          this.sendMessage({
            type: MESSAGES_TYPES.commentsFetched,
            comments: this.formatedComments,
          })
          break
        case MESSAGES_TYPES.deleteComment:
          this.commentStore.archiveOrUnarchiveComment(
            this.projectStore.selectedProjectExid,
            data?.commentId,
            true
          )
          break
        case MESSAGES_TYPES.createComment:
          const comment = {
            position3d: [
              data?.comment.point.position.x,
              data?.comment.point.position.y,
              data?.comment.point.position.z,
            ],
            content: data?.comment.point.content,
            label: data?.comment.point.label,
            context: {
              dateId: data?.comment.dateId,
              floorId: data?.comment.floorId,
              cameraRotation: data?.comment.point.cameraRotation,
              currentRoom: data?.comment.point.currentRoom,
            },
          }

          this.commentStore.addComment(
            this.projectStore.selectedProjectExid,
            comment
          )
          break
        default:
          break
      }
    },
    async sendMessage(message) {
      const frameViewerRef = this.$refs.frameViewer // This could be Pano-iFrame, Itwin-iFrame or Forge-iFrame
      const frameViewerContainer = frameViewerRef?.$refs.container

      frameViewerContainer?.contentWindow?.postMessage(message, this.origin)
    },
    openRecordings(cameraExid) {
      this.recordingsCameraExid = null
      this.$setTimeout(() => {
        this.recordingsCameraExid = cameraExid
      })
    },
    openImageEditor(urlData, data360) {
      this.$analytics.saveEvent(AnalyticsEvent.MarkUpToolOpen)
      this.currentSnapshot = {
        snapshot: {
          data: urlData,
          createdAt: new Date(),
        },
        camera: this.firstCamera,
      }
      this.isEditingImage = true

      this.data360 = data360
    },
    saveAnalyticsEvent(action, context) {
      this.$analytics.saveEvent(`${context ? context + "-" : ""}${action}`)
    },
    pushQueryParams(parameters) {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        ...parameters,
      }
      this.$router.push({
        path: this.$route.path,
        query: this.currentQueryParams,
      })
    },
    shareLink() {
      this.$analytics.saveEvent(AnalyticsEvent.ThreeSixtyShareView)
      const link = window.location.href
      navigator.clipboard.writeText(link)
      this.$notifications.success("Link copied to clipboard.")
    },
    on360UplaodDialogOpen() {
      const uploadStats = {
        percentage: 0,
        totalSize: 0,
        uploadedSize: 0,
        isUploading: false,
        isProcessing: false,
        uploadFinished: false,
        uploadType: INGEST_UPLOADS_TYPES._360Assets,
      }
      this.ingestFileUploaderStore.currentUploadStats = uploadStats
      this.ingestFileUploaderStore.uploadStats.push(uploadStats)
      this.upload360Dialog = true
    },
    on360UplaodDialogClosed() {
      this.ingestFileUploaderStore.currentBIMUploadStat = undefined
      this.upload360Dialog = false
      this.upload360Object = undefined
    },
    formatComment(comment) {
      return {
        id: comment.id,
        position: {
          x: comment.position3d[0],
          y: comment.position3d[1],
          z: comment.position3d[2],
        },
        content: comment.content,
        label: comment.label,
        dateID: comment.context.dateId,
        floorID: comment.context.floorId,
        cameraRotation: comment.context.cameraRotation,
        currentRoom: comment.context.currentRoom,
      }
    },
  },
}
